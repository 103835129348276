<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                
               <a-form-item label="问题部位">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.position" />
                </a-form-item>
                <a-form-item label="问题描述">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.description" />
                </a-form-item>
                <a-form-item label="问题等级">
                    <a-select class="search-select" placeholder="请选择" v-model="searchInfo.rank">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="(item,key) in rankMap" :key="key" :value="item.id">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="检查人" >
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.checkPersonName" />
                </a-form-item>
                <a-form-item label="状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">待整改</a-select-option>
                        <a-select-option :value="1">待复查</a-select-option>
                        <a-select-option :value="-1">已撤回</a-select-option>
                        <a-select-option :value="2">已关闭</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="超时状态" >
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.timeout">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">正常整改</a-select-option>
                        <a-select-option :value="1">超时整改</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="检查时间" >
                    <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button class="item" @click="exprotFile('safeRecordExcel',{params:searchInfo,dateArray:['startDate','endDate']})"><a-icon type="export" />导出</a-button>
                <router-link class="item" to="/ssms/inspection/record/add">
                    <a-button type="primary">添加</a-button>
                </router-link>
            </a-row>
        </section>

        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="recordLists" :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                <span slot="status" slot-scope="text">{{text === '-1' ? '已撤回' : text === '1' ? '待复核' : text === '2' ? '已关闭' : '待整改'}}</span>
                <!-- 操作 -->
                <a slot="action" slot-scope="text,list">
                    <router-link class="m-r-10" :to="{path:'/ssms/inspection/record/details',query:{id:list.id}}">查看</router-link>
                   
                    <router-link v-if="list.status === '0' && list.checkPerson === userId" class="m-r-10" :to="{path:'/ssms/inspection/record/edit',query:{id:list.id}}">编辑</router-link>
                    
                    <router-link v-if="list.status === '0' && list.rectifyPerson === userId" class="m-r-10" :to="{path:'/ssms/inspection/record/details',query:{id:list.id,type:2}}">整改</router-link>

                    <router-link v-if="list.status === '1' && list.recheckPerson === userId" class="m-r-10" :to="{path:'/ssms/inspection/record/details',query:{id:list.id,type:3}}">复核</router-link>
                   
                    <a-popconfirm v-if="list.status === '0' && list.rejectStatus === '0' && list.checkPerson === userId" class="m-r-10" title="确定撤回?" @confirm="cancelItem(list.id)">撤回</a-popconfirm>
                   
                    <a-popconfirm  v-if="list.status === '-1' || list.status === '2'" title="确定删除?" @confirm="deleteItem(list)">删除</a-popconfirm>
                </a>
            </a-table>
        </section>
    </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key',width:80},
    {title:'问题部位',dataIndex:'position',align:'center',key:'position',width:200,ellipsis:true},
    {title:'问题描述',dataIndex:'description',align:'center',key:'description',width:220,ellipsis:true},
    {title:'问题等级',dataIndex:'rankName',align:'center',key:'rankName'},
    {title:'检查人',dataIndex:'checkPersonName',align:'center',key:'checkPersonName'},
    {title:'检查时间',dataIndex:'checkTime',align:'center',key:'checkTime'},
    {title:'整改期限',dataIndex:'rectifyDuration',align:'center',key:'rectifyDuration'},
    {title:'关闭时间',dataIndex:'closeTime',align:'center',key:'closeTime',width:160},
    {title:'整改人',dataIndex:'rectifyPersonName',align:'center',key:'rectifyPersonName'},
    {title:'状态',dataIndex:'status',align:'center',key:'status',scopedSlots:{customRender: "status"}},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center',width:170},
]
import {exprotFile} from '@/until/unit'
export default {
    data(){
        return{
            columns,
            total:0,
            pageNum:1,
            pageSize:9,
            recordLists:[],
            rankMap:[],
            searchInfo:{
                endDate:[]
            },
            
        }
    },
     beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.querySafeRecordList()
        this.querySafeLevelList()
    },
    computed:{
        userId(){
            return this.$store.state.userInfo.userId
        }
    },
    methods:{
        exprotFile,     //导出
        //等级下拉
        querySafeLevelList(){
            this.$api.querySafeLevelList().then(res =>{
                if(res.code === 200){
                    this.rankMap = (res.data && res.data.records) || []
                }
            })
        },
        //查询巡检列表
        querySafeRecordList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            params.size = this.pageSize
            params.startDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : ''
            params.endDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
            this.$api.querySafeRecordList(params).then(res =>{
                if(res.code === 200){
                    this.recordLists = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                    this.recordLists.forEach((item,ind) =>{
                        item.key = (this.pageSize*(this.pageNum - 1))+ind+1
                         item.checkTime=item.checkTime.split(' ')[0]
                    })
                }
            })
        },
        changePageNum(p){
            this.pageNum = p.current
            this.querySafeRecordList()
        },
        //重置
        reset(){
            this.searchInfo = {
                endDate:[]
            }
            this.pageNum = 1;
            this.querySafeRecordList()
        },
        search(){
            this.pageNum = 1;
            this.querySafeRecordList()
        },
        //删除
        deleteItem(list){
            this.$api.deleteSafeRecord(list.id).then(res =>{
                if(res.code === 200){
                    this.querySafeRecordList()
                }
            })
        },
        //撤回
        cancelItem(id){
            this.$api.updateSafeRecord({id,status:-1}).then(res =>{
                if(res.code === 200){
                    this.querySafeRecordList()
                }
            })
        }
    }
}
</script>